import { useState, useEffect } from 'react';
/**
 * useErrors
 *
 * This returns an array of errors if the fieldname matches the error key
 *
 * @param object errors -- GF error object
 * @param String fieldName -- field to verify
 * @returns array of errors
 */
function useErrors(errors, fieldName) {
    const [fieldErrors, setFieldErrors] = useState([]);

    useEffect(() => {
        let errorArr = [];
        for (const key in errors) {
            if (fieldName === key) errorArr.push(errors[key]);
        }
        setFieldErrors(errorArr);
    }, [errors]);

    return fieldErrors;
}

export default useErrors;
