import React from 'react';
import PropTypes from 'prop-types';
//proptypes
const proptypes = {
    error: PropTypes.string.isRequired,
};
/**
 * Error
 *
 * Used to display a field error
 * @param {PropTypes}
 * @returns
 */
const Error = ({ error }) => {
    return (
        <div className="error">
            <p className="error__text">{error}</p>
        </div>
    );
};

Error.propTypes = proptypes;
export default Error;
