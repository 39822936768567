import React from 'react';
import PropTypes from 'prop-types';
//Components
import InputHeader from '../Partials/InputHeader';
//Hooks
import useErrors from '../Hooks/useErrors';
//Functions
import { formatFieldId } from '../Utilities/Helper';
//PropTypes
const proptypes = {
    field: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    rootClass: PropTypes.string,
};

/**
 * Gravity Forms TextareaField Component
 * @param {PropTypes}
 * @returns
 */
const TextareaField = ({
    field,
    onChange,
    errors,
    rootClass = 'textarea-field',
}) => {
    const name = 'input_' + formatFieldId(field.id);
    const fieldErrors = useErrors(errors, name);
    const additionalParams = {};

    if (field.isRequired) additionalParams['required'];

    return (
        <div className={`field ${rootClass} ${field.cssClass}`}>
            <InputHeader
                field={field}
                fieldName={name}
                errors={fieldErrors}
                rootClass={rootClass}
            />
            <textarea
                name={name}
                placeholder={field.placeholder}
                className={`${rootClass}__textarea${
                    fieldErrors.length > 0 ? ' input-error' : ''
                }`}
                onChange={e => {
                    onChange(name, e.target.value);
                }}
                {...additionalParams}></textarea>
        </div>
    );
};

TextareaField.propTypes = proptypes;
export default TextareaField;
