import React from 'react';
import PropTypes from 'prop-types';
//Components
import Error from './Error';
//Proptypes
const proptypes = {
    field: PropTypes.object.isRequired,
    fieldName: PropTypes.string.isRequired,
    errors: PropTypes.array.isRequired,
    rootClass: PropTypes.string.isRequired,
};
/**
 * Input Header
 *
 * Displays label, description and errors if present
 * @param {PropTypes}
 * @returns
 */
const InputHeader = ({ field, fieldName, errors, rootClass }) => {
    /**
     * Display errors
     * @returns
     */
    const displayErrors = () => {
        if (errors.length > 0) {
            return errors.map((error, key) => {
                return (
                    <Error
                        error={error}
                        key={`error_key_${fieldName}_${key}`}
                    />
                );
            });
        }
    };

    return (
        <div className="field-header">
            {field.label.length > 0 && (
                <label
                    htmlFor={fieldName}
                    className={'label ' + rootClass + '__label'}>
                    {field.label}
                    {field.isRequired && (
                        <span
                            className={`label__required ${rootClass}__label-required`}>
                            *
                        </span>
                    )}
                </label>
            )}
            {field.description.length > 0 && (
                <p className={`${rootClass}__description`}>
                    {field.description}
                </p>
            )}
            {errors.length > 0 && displayErrors()}
        </div>
    );
};

InputHeader.propTypes = proptypes;
export default InputHeader;
